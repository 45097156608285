import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Input, Typography, WelcomeContainer } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateRegistrationRequestDto } from '@escapenavigator/types/dist/registration-request/create-registration-request.dto';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { isRu } from '@escapenavigator/utils/dist/is-ru';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import { InputTel } from 'src/components/input-tel';
import { Logo } from 'src/components/logo';
import successImage from 'src/images/complete.webp';
import image from 'src/images/login.webp';
import { useApi } from 'src/providers/api/context';

import styles from 'src/pages/index.module.css';

type Props = {
    type: 'questroom' | 'partner';
};

export const RequestCall: React.FC<Props> = ({ type }) => {
    const { t } = useTranslation();
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    // const links = getLinks(CountriesEnum.GERMANY, process.env.REACT_APP_ORDERS_DOMAIN);

    const { registrationRequests } = useApi();

    const {
        createError, createFetch, createStatusOk, createLoading,
    } = useApiMethod({
        api: registrationRequests.create,
    });

    if (createStatusOk) {
        return (
            <WelcomeContainer color="navigator" image={ successImage }>
                <Head title={ t('afterRegistration.title') } subtitle={ t('afterRegistration.text') } />
            </WelcomeContainer>
        );
    }

    if (type === 'partner') {
        return (
            <WelcomeContainer image={ image } color="navigator">
                <Logo />
                <Head
                    subtitle="Thank you for your interest in exploring a partnership with us!"
                    title="Partnership Details Request Form"
                />
                <AuthForm
                    initialValues={ serializeRecord(CreateRegistrationRequestDto, {
                        comment: 'Partner',
                        site: 'www.partner.com',
                    }) }
                    onSubmit={ (data) => createFetch({ data }) }
                    buttonText="Request Details"
                    error={ createError && createError.message }
                    loading={ createLoading }
                    t={ t }
                >
                    { ({
                        values, handleChange, touched, errors,
                    }) => (
                        <React.Fragment>
                            <Input
                                value={ values.phone }
                                onChange={ handleChange('phone') }
                                className={ styles.input }
                                error={ touched.phone && errors.phone }
                                label={ t('registrationQuestrooms.phoneLabel') }
                                required={ true }
                                dataTestId="phone"
                            />
                            <Input
                                value={ values.email }
                                onChange={ handleChange('email') }
                                className={ styles.input }
                                error={ touched.email && errors.email }
                                label={ t('forms.email') }
                                required={ true }
                                dataTestId="email"
                            />
                        </React.Fragment>
                    ) }
                </AuthForm>
            </WelcomeContainer>
        );
    }

    return (
        <WelcomeContainer image={ image } color="navigator">
            <Logo />
            <Head title={ t('registrationQuestrooms.label') } />

            <AuthForm
                initialValues={
                    serializeRecord(
                        CreateRegistrationRequestDto,
                        {},
                    ) as unknown as CreateRegistrationRequestDto
                }
                onSubmit={ (data) => createFetch({ data }) }
                buttonText={ t('registrationQuestrooms.button') }
                error={ createError && createError.message }
                loading={ createLoading }
                t={ t }
            >
                { ({
                    values, handleChange, setFieldValue, touched, errors,
                }) => (
                    <React.Fragment>
                        <InputTel
                            value={ values.phone }
                            initialCountry={ isRu() ? 'ru' : 'us' }
                            onChange={ (value) => {
                                setFieldValue('phone', value.phone);
                                setIsPhoneValid(value.isValid);
                            } }
                            error={
                                (touched.phone && errors.phone) ||
                                (touched.phone && !isPhoneValid && t('Phone not valid'))
                            }
                        />
                        <Input
                            value={ values.email }
                            onChange={ handleChange('email') }
                            className={ styles.input }
                            error={ touched.email && errors.email }
                            label={ t('forms.email') }
                            dataTestId="email"
                        />

                        <Input
                            value={ values.site }
                            onChange={ handleChange('site') }
                            error={ touched.site && errors.site }
                            className={ styles.input }
                            label={ t('forms.link') }
                            dataTestId="site"
                        />
                    </React.Fragment>
                ) }
            </AuthForm>

            <Typography.Text className={ styles.polices } view="title" color="secondary">
                { /* { t('allLinks.beforeRegistration') }{ ' ' }
                <a target="_blank" href={ links.AGREEMENT } rel="noreferrer">
                    { t('allLinks.agreement') }
                </a>
                ,{ ' ' }
                <a target="_blank" href={ links.PRIVACY_POLICY } rel="noreferrer">
                    { t('allLinks.policy') }
                </a>
                ,{ ' ' }
                <a target="_blank" href={ links.REGISTRATION_NEW_PROFILE } rel="noreferrer">
                    { t('allLinks.newProfileOffer') }
                </a>{ ' ' }
                <br />
                <br /> */ }
                { t('allLinks.alreadyHasAccount') }{ ' ' }
                <Link className={ styles.link_primary } to="/auth/questrooms">
                    { t('allLinks.login') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
