import React, { useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { FlexColumns, Typography } from '@alphakits/ui';

import 'react-phone-input-2/lib/style.css';
import styles from './index.module.css';

type Props = {
    value: string;
    onChange: (props: { phone: string; isValid: boolean }) => void;
    initialCountry: string;
    singleCountry?: boolean;
    error?: string;
};

export const InputTel: React.FC<Props> = ({
    value,
    onChange,
    initialCountry,
    error,
    singleCountry,
}) => {
    const [country, setCountry] = useState(initialCountry);
    const [libLoaded, setLibLoaded] = useState(false);

    const validatePhoneNumber = async (v: string) => {
        if (!libLoaded) {
            const lib = await import('libphonenumber-js');

            setLibLoaded(true);

            return lib.isValidPhoneNumber(v);
        }
        const lib = await import('libphonenumber-js');

        return lib.isValidPhoneNumber(v);
    };

    const handleChange = async (v: string, c: CountryData) => {
        const phone = `+${v}`;

        console.log(singleCountry);

        const isValid = await validatePhoneNumber(phone);

        setCountry(c.countryCode);

        onChange({ phone, isValid });
    };

    useEffect(() => {
        const initialCheck = async () => {
            if (value) {
                const phone = `+${value.replace(/\D/g, '')}`;

                onChange({
                    phone,
                    isValid: await validatePhoneNumber(phone),
                });
            }
        };

        initialCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FlexColumns columns={ 1 } gr={ 4 }>
            <PhoneInput
                containerClass={ styles.container }
                buttonClass={ styles.dropdown }
                inputClass={ styles.input }
                country={ country }
                enableClickOutside={ false }
                value={ value }
                onChange={ handleChange }
            />

            { !!error && (
                <Typography.Text view="caps" color="negative">
                    { error }
                </Typography.Text>
            ) }
        </FlexColumns>
    );
};
